import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from "next/legacy/image";
import UserCategoryBadge from './UserCategoryBadge';

const iconAvatarDefault = '/images/icon_avatar_default.svg'
const Rounded = {
    'none': 'rounded-none',
    'sm': 'rounded-sm',     // 2px
    'regular': 'rounded',   // 4px
    'md': 'rounded-md',     // 6px
    'lg': 'rounded-lg',     // 8px
    'xl': 'rounded-xl',     // 12px
    '2xl': 'rounded-2xl',   // 16px
    '3xl': 'rounded-3xl',   // 24px
    'full': 'rounded-full',
}

const Avatar = ({ className, rounded, src, handlerClick, label, alt, userCategory, profileSize }) => {
    const [imageSrc, setImageSrc] = useState(src || iconAvatarDefault);

    useEffect(() => {
        setImageSrc(src || iconAvatarDefault);
    }, [src]);

    return (
        <div className={`${className} flex justify-center items-center  text-center text-white text-xl relative`}>
            <div className={`${Rounded[rounded]} bg-gray-300 overflow-hidden w-full h-full relative`}>

                {label
                    ? <span className="font-bold leading-10 text-primary1-600 flex justify-center items-center h-full">{label}</span>
                    : <Image {...(profileSize ? { width: profileSize, height: profileSize, objectFit: 'cover' } : { layout: 'fill', objectFit: 'cover' })} onClick={handlerClick} src={imageSrc} className={`transform`} onError={(e) => { setImageSrc(iconAvatarDefault); console.log('Error res.hasHeader: Avatar', imageSrc) }} alt={alt} />
                }
            </div>
            {userCategory && <UserCategoryBadge className='absolute top-[7%] -left-[20%] w-[144%]' userCategory={userCategory} type="RING" />}
        </div>

    )
}

export default Avatar;

Avatar.defaultProps = {
    rounded: 'full',
}

Avatar.propTypes = {
    /**
     * Apply tailwind classes you want to need
     */
    className: PropTypes.string,
    rounded: PropTypes.oneOf(['none', 'sm', 'regular', 'md', 'lg', 'xl', '2xl', '3xl', 'full']),
    /**
     * The avatar will be in url formate
     */
    src: PropTypes.string.isRequired,
    label: PropTypes.string,
    alterText: PropTypes.string,
    userCategory: PropTypes.oneOf(['IRON', 'SILVER', 'GOLD', 'DIAMOND', 'PLATINUM']),
};