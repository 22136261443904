import * as HttpService from "./http.service";
import {
    GET_ALL_CLINICS, GET_CLINIC_BLOGS, GET_CLINIC_DETAILS, GET_CLINIC_DOCTORS, GET_CLINIC_IMAGES, CREATE_CLINIC_PROFILE_URL, UPDATE_CLINIC_PROFILE_URL, CREATE_CLINIC_VIDEO_URL, GET_CLINIC_VIDEO_URL, DELETE_CLINIC_VIDEO_URL, GET_CLINIC_PROFILE_URL, UPDATE_CLINIC_TIMING_URL, GET_CLINIC_TIMING_URL, ADD_CLINIC_SERVICES, DELETE_CLINIC_SERVICES,
    GET_CLINICS_BY_DOCTOR_ID,
    UPDATE_CLINIC_FEE,
    GET_ENCRYPTED_TEXT,
    GET_ORDER_DETAILS_FOR_PREPAID_PAYMENT,
    GET_ALL_CLINICS_V1, GET_DECRYPTED_TEXT} from "./url.service";


export const getAllClinics = (skip, limit, locationSearch, clinicSearch, isSmartClinic = '') => {
        return HttpService.getWithOutAuth(GET_ALL_CLINICS(skip, limit, locationSearch, clinicSearch, isSmartClinic));
}
    
export const getAllClinicsV1 = ({skip, limit, clinicSearch, locationSearch, state, services, fee, clinicIds, clinicSlugs, isSmartClinic, gender, experience}) => {
    return HttpService.getWithOutAuth(GET_ALL_CLINICS_V1(skip, limit, clinicSearch, locationSearch, state, services, fee, clinicIds, clinicSlugs, isSmartClinic, gender, experience))
}

export const getClinicDetails = (slug, city) => {
    return HttpService.getWithOutAuth(GET_CLINIC_DETAILS(slug, city));
}


export const getClinicDoctors = (id, skip, limit) => {
    return HttpService.getWithOutAuth(GET_CLINIC_DOCTORS(id, skip, limit));
}

export const getClinicBlogs = (id, skip, limit) => {
    return HttpService.getWithOutAuth(GET_CLINIC_BLOGS(id, skip, limit));
}

export const getClinicImages = (id, skip, limit) => {
    return HttpService.getWithOutAuth(GET_CLINIC_IMAGES(id, skip, limit));
}

export const createClinicProfile = (data) => {
    return HttpService.postWithAuth(CREATE_CLINIC_PROFILE_URL(), data);
}

export const updateClinicProfile = (data) => {
    return HttpService.postWithAuth(UPDATE_CLINIC_PROFILE_URL(), data);
}

export const createClinicVideo = (clinic_id, data) => {
    return HttpService.postWithAuth(CREATE_CLINIC_VIDEO_URL(clinic_id), data);
}

export const getClinicVideo = (clinic_id) => {
    return HttpService.getWithAuth(GET_CLINIC_VIDEO_URL(clinic_id));
}

export const deleteClinicVideo = (data) => {
    return HttpService.postWithAuth(DELETE_CLINIC_VIDEO_URL(), data);
}

export const getClinicProfile = (doctor_id) => {
    return HttpService.getWithAuth(GET_CLINIC_PROFILE_URL(doctor_id));
}

export const getClinicTiming = (clinic_id) => {
    return HttpService.getWithAuth(GET_CLINIC_TIMING_URL(clinic_id));
}

export const updateClinicTiming = (data) => {
    return HttpService.postWithAuth(UPDATE_CLINIC_TIMING_URL(), data);
}

export const AddClinicServices = (data) => {
    return HttpService.postWithAuth(ADD_CLINIC_SERVICES(), data);
}

export const DeleteClinicService = (clinicId, serviceId, userId) => {
    return HttpService.putWithAuth(DELETE_CLINIC_SERVICES(clinicId, serviceId), { userId });
}

export const getClinicsByDoctorId = ({ doctorId }) => {
    return HttpService.getWithAuth(GET_CLINICS_BY_DOCTOR_ID({ doctorId }));
};

export const updateClinicFee = (doctorId, data) => {
    return HttpService.putWithAuth(UPDATE_CLINIC_FEE(doctorId), data);
}                                                                                                                                                                                                                                                                                                                                       

export const getEncyptedText = (text) => {
    return HttpService.getWithAuth(GET_ENCRYPTED_TEXT(text));
}  

export const getDecryptedText = (text, token) => {
    return HttpService.getWithToken(GET_DECRYPTED_TEXT(text),token); 
}  

export const getOrderDetailsForPrepaidPayment = (hashedId) =>{
    return HttpService.getWithAuth(GET_ORDER_DETAILS_FOR_PREPAID_PAYMENT(hashedId));
}