import * as HttpService from "./http.service";
import {
    SEMANTIC_SEARCH,
    GET_TRANSLITERATED_TEXT,
    GET_UNIVERSAL_SEARCH_RESULTS,
} from "./url.service";


export const getSearchResults = ({ page = 0, limit = 10, search = "", city = "" }) => {
    return HttpService.getWithOutAuth(SEMANTIC_SEARCH({ page, limit, search, city }));
};

export const getTransliteratedText = ({ text, promptAction = 1 }) => {
    return HttpService.getWithOutAuth(GET_TRANSLITERATED_TEXT({ text, promptAction }));
};

export const getUniversalSearchResults = ({ page = 0, limit = 5, search = "", option = 0 }) => {
    return HttpService.getWithAuth(GET_UNIVERSAL_SEARCH_RESULTS({ page, limit, search, option }));
};
