import { getBlogCategories, getBlogsList } from "services/blogs.service";

async function getBlogsListing({ page, categories, featured = false, search, sortby = 1, language, mediaType }) {
    try {
        const response = await getBlogsList({ page, categories, featured, search, sortby, language, mediaType });
        if (response.status) {
            return {
                count: response?.entity.count,
                rows: response?.entity.rows
                    .filter(item => item !== null)
                    .map(item => ({
                        ...item,
                        thumbnail: item.thumbnail === null ? null : `${process.env.NEXT_PUBLIC_BUCKET_URL}${item.thumbnail}`
                    }))
            };
        }
        throw response.message;
    } catch (error) {
        throw error?.message;
    }
};

async function getBlogsCatgeoriesAndListing(language, mediaType) {
    try {
        const blogs = await getBlogsListing({ page: 1, language, mediaType });
        const categories = await getBlogCategories({ language, blogType:mediaType });
        if (!categories.status) {
            throw categories.message;
        }
        return {
            status: true,
            ...blogs,
            categories: categories.entity
        }
    } catch (error) {
        return {
            status: false,
            message: error
        }
    }
}

export {
    getBlogsListing,
    getBlogsCatgeoriesAndListing
};