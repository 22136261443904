import React from 'react';
import PropTypes from "prop-types";
import Image from "next/legacy/image"


const IconImage = ({ IconSrc, alt, imageWidth, imageHeight, layout, className = '' }) => {
    return (
        <div className={`${className} w-full flex`}>
            <Image className='w-full' width={imageWidth} height={imageHeight} {...(!(imageWidth && imageHeight) && { layout })} src={IconSrc} alt={alt} onError={() => console.log('Error res.hasHeader: IconImage', IconSrc)} />
        </div>
    )
}

IconImage.defaultProps = {
    alterText: 'Image Icon',
    layout: 'fill',
}

IconImage.propTypes = {
    IconSrc: PropTypes.string,
    className: PropTypes.string,
    alterText: PropTypes.string,
    layout: PropTypes.oneOf(['fill', 'fixed', 'intrinsic', 'responsive']),
}

export default IconImage;
