const userCategoryMapping = {
    0: "website",
    1: "iron",
    2: "silver",
    3: "gold",
    4: "diamond",
    5: "platinum",
    6: "reseller"
};



export const getTransformedPackagesPrices = (packageList, discountList, userCategory) => {
    const discountForCategory = userCategoryMapping[userCategory];
    const productPackageLevelPricing = [];

    packageList?.map((packageInfo) => {
        let discount = discountList?.filter((discountInfo) => discountInfo.packaging_id === packageInfo.package_variant_id);
        let discountPercent = 0;
        if (userCategory === 6) {
            discountPercent = (discount[0]?.website && discount[0]?.website > discount[0]?.[discountForCategory]) ? discount[0]?.website - discount[0]?.[discountForCategory] : 0;
        } else {
            discountPercent = userCategory === 0 ? discount[0]?.website : discount[0]?.website + discount[0]?.[discountForCategory];
        }
        const discountPrice = Number(packageInfo.mrp) - Number((discountPercent / 100) * packageInfo.mrp);

        let packageLevelObj = {
            id: packageInfo?.id ?? 0,
            package_id: packageInfo?.package_id,
            price: packageInfo?.mrp,
            discounted_price: discountPrice ? discountPrice : packageInfo?.mrp,
            discount_percent: discountPercent ? discountPercent : '0',
            no_of_unit: packageInfo?.no_of_unit || 0,
            total_available_unit: packageInfo?.total_available_unit || 0,
            medicine_packaging_size: packageInfo?.packaging.name
        };
        productPackageLevelPricing.push(packageLevelObj);
    });

    return productPackageLevelPricing;

}